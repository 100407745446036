import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="bg-gray-900 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <h1><Link to="/" className="text-white text-2xl font-bold">Farmshark</Link></h1>
        <ul className="flex space-x-4 items-center">
          <li><Link to="/" className="text-gray-300 hover:text-white">Home</Link></li>
          <li><Link to="/login" className="text-gray-300 hover:text-white">Login</Link></li>
          <li><Link to="/dashboard" className="text-gray-300 hover:text-white">Dashboard</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
