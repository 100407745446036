import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated, logout } from '../utils/auth';

const Dashboard = () => {
  const navigate = useNavigate();

  if (!isAuthenticated()) {
    navigate('/login');
  }

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 bg-gray-800">
      <div className="bg-gray-700 text-gray-200 p-8 rounded-lg shadow-lg text-center">
        <h2 className="text-3xl font-semibold mb-6">Dashboard</h2>
        <p className="mb-8">Welcome to your dashboard!</p>
        <button
          onClick={handleLogout}
          className="bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-500"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
