export const loginUser = async (username, password) => {
  try {
    const response = await fetch('/api/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });
    const data = await response.json();

    if (data.token) {
      localStorage.setItem('token', data.token);
      return { success: true };
    } else {
      return { success: false, message: data.error };
    }
  } catch (error) {
    return { success: false, message: 'An error occurred. Please try again.' };
  }
};

export const isAuthenticated = () => {
  return localStorage.getItem('token') !== null;
};

export const logout = () => {
  localStorage.removeItem('token');
};

