import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LicenseModal = ({ license, onClose }) => {
  const [creatingAccount, setCreatingAccount] = useState(false);
  const navigate = useNavigate();

  const handlePurchase = () => {
    setCreatingAccount(true);
  };

  const handleCreateAccount = async (username, password) => {
    try {
      const response = await fetch('http://localhost:4000/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // Navigate to payment page after successful account creation
        navigate('/payment');
      } else {
        alert(data.message || 'Account creation failed');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while creating the account');
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-700 text-gray-200 p-6 rounded-lg shadow-lg w-80">
        <h2 className="text-2xl font-semibold mb-4">Purchase {license.name}</h2>
        <p className="mb-4">Price: {license.price}</p>
        <p className="mb-6">More details about the license will go here.</p>
        {!creatingAccount ? (
          <>
            <button
              onClick={handlePurchase}
              className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-500 w-full mb-4"
            >
              Purchase
            </button>
            <button
              onClick={onClose}
              className="bg-gray-300 text-gray-800 py-2 px-4 rounded-lg hover:bg-gray-200 w-full"
            >
              Close
            </button>
          </>
        ) : (
          <CreateAccountForm onCreateAccount={handleCreateAccount} onClose={onClose} />
        )}
      </div>
    </div>
  );
};

const CreateAccountForm = ({ onCreateAccount, onClose }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    onCreateAccount(username, password);
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-6 text-center">Create an Account</h2>
      {error && <p className="text-red-500 text-center mb-4">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="w-full p-2 mb-4 border rounded-lg bg-gray-600 border-gray-500"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full p-2 mb-4 border rounded-lg bg-gray-600 border-gray-500"
        />
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-500"
        >
          Create Account
        </button>
      </form>
      <button
        onClick={onClose}
        className="bg-gray-300 text-gray-800 py-2 px-4 rounded-lg hover:bg-gray-200 w-full mt-4"
      >
        Close
      </button>
    </div>
  );
};

export default LicenseModal;
