import React from 'react';
import Pricing from '../components/Pricing';

const Home = () => {
  return (
    <div className="bg-gray-800 text-gray-200 min-h-screen flex flex-col items-center justify-center">
      <h2 className="text-4xl font-bold mb-4">Farmshark</h2>
      <p className="text-lg mb-8">Automate your NBA 2K25 VC farming</p>
      
      <a
        href="https://discord.gg/your-discord-invite-link"
        target="_blank"
        rel="noopener noreferrer"
        className="mb-8 inline-block bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-500"
      >
        Join Our Discord Server
      </a>

      <Pricing />
    </div>
  );
};

export default Home;
