import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

// Load Stripe
const stripePromise = loadStripe('pk_test_51PswQFHT0HqR0Ze6SR8BcDoopiAUTxe11iV4XivfVGeg9t6rxnV8RQqPWamwbCsgo1UIbU4quKcb1AB9wSxtkgHW00cPx4AYnf'); // Replace with your Stripe publishable key

const CheckoutForm = ({ amount }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { data: { clientSecret } } = await fetch('http://localhost:4000/api/payment/create-payment-intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount }),
    }).then(res => res.json());

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'Customer Name',
        },
      },
    });

    if (error) {
      console.error(error);
      alert('Payment failed');
    } else if (paymentIntent.status === 'succeeded') {
      alert('Payment successful!');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-4 p-4">
      <CardElement className="mb-4 p-2 border border-gray-600 rounded-md" />
      <button
        type="submit"
        className="bg-gray-800 text-white py-2 px-4 rounded-lg hover:bg-gray-700 w-full"
        disabled={!stripe}
      >
        Pay with Stripe
      </button>
    </form>
  );
};

const Payment = () => {
  useEffect(() => {
    // Check if PayPal Buttons are already initialized
    if (window.paypal && !document.querySelector('#paypal-button-container').hasChildNodes()) {
      window.paypal.Buttons({
        createOrder: async (data, actions) => {
          return fetch('http://localhost:4000/api/payment/create-paypal-order', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ amount: 10.00 }), // Example amount
          })
          .then(res => res.json())
          .then(order => order.id);
        },
        onApprove: async (data, actions) => {
          return actions.order.capture().then(() => {
            alert('Payment successful!');
          });
        },
        onError: (err) => {
          console.error('PayPal error:', err);
        },
      }).render('#paypal-button-container');
    }
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-800">
      <div className="bg-gray-700 text-gray-200 p-8 rounded-lg shadow-lg w-full max-w-4xl">
        <h2 className="text-2xl font-semibold mb-6 text-center">Choose Payment Method</h2>
        <div className="flex flex-wrap gap-8 justify-center">
          {/* Stripe Section */}
          <div className="flex-1 min-w-[300px] max-w-[500px]">
            <h3 className="text-xl mb-4 text-center">Stripe</h3>
            <Elements stripe={stripePromise}>
              <CheckoutForm amount={1000} /> {/* Example amount in cents */}
            </Elements>
          </div>
          {/* PayPal Section */}
          <div className="flex-1 min-w-[300px] max-w-[500px]">
            <h3 className="text-xl mb-4 text-center">PayPal</h3>
            <div id="paypal-button-container" className="w-full"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
