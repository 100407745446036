import React, { useState } from 'react';
import LicenseModal from './LicenseModal';

const licenses = [
  { id: 1, name: '1 Day', price: '$5' },
  { id: 2, name: '3 Days', price: '$12' },
  { id: 3, name: '1 Week', price: '$25' },
  { id: 4, name: '1 Month', price: '$80' },
  { id: 5, name: '3 Months', price: '$200' },
  { id: 6, name: 'Lifetime', price: '$500' },
];

const Pricing = () => {
  const [selectedLicense, setSelectedLicense] = useState(null);

  const handleLicenseClick = (license) => {
    setSelectedLicense(license);
  };

  const handleCloseModal = () => {
    setSelectedLicense(null);
  };

  return (
    <div className="bg-gray-700 text-gray-200 shadow-md rounded-lg p-6">
      <h3 className="text-2xl font-semibold mb-4">Available Licenses</h3>
      <div className="flex flex-wrap justify-center gap-4">
        {licenses.map((license) => (
          <button
            key={license.id}
            onClick={() => handleLicenseClick(license)}
            className="bg-gray-200 bg-gray-600 text-gray-800 text-gray-200 p-4 rounded-lg hover:bg-gray-500 w-full md:w-auto"
          >
            {license.name} - <span className="font-bold">{license.price}</span>
          </button>
        ))}
      </div>
      {selectedLicense && (
        <LicenseModal license={selectedLicense} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default Pricing;
